.loading-container {
  min-height:150px;
  position: relative;
}
.loading-container-full {
  height: calc(100vh - 64px); /* Fallback for older browsers */
  height: calc(100dvh - 64px); /* Modern browsers */
  display: flex;
  align-items: center;
  justify-content: center;
  .loading-app {
    position: relative;
  }
}
.loading-container-p {
  padding:15px;
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid var(--brand-500);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-app {
  position: absolute;
  left:0px;
  right:0px;
  top:0px;
  bottom: 0px;
  > div {
    margin: 0 auto;
  }
}


.error-loading {
  font-size:16px;
  text-align: center;
  padding:20px;
}