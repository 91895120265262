ul.list-inline {
  margin-left:0px;
  padding-left: 0px;
  list-style: none;
  li {
    display: inline-block;
    margin-right:5px;
    padding-right:3px;
    &:after {
      content: " \00b7";
      padding-left:4px;
    }
    &.list-inline-item-multi {
      margin-right: 0px;
      padding-right: 0px;
      display: block;
      &:after {
        content: none;
        padding-left:0px;
      }
    }
    &.list-inline-item-multi:after {
    }
  }
  > li:last-child:after  {
    content: none;
  }
}