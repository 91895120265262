.modal-product-full {
  .modal-body {
    background-color: transparent;
    padding: 0;

    .product-item {
      padding-top: 10px;
    }
  }

  .modal-content {
    display: flex;
  }

  .product-item-footer, .modal-footer {
    background-color: #fff;
    position: sticky;
    z-index: 1000;
    opacity: 1;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .modal-product-full-right {
    background-color: $color-secondary;

    .container-fluid {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .modal-body {
        flex: 1 1;
      }
    }
  }

  .modal-header {
    padding-left: 0;
    padding-right: 0;
    position: sticky;
    top: 0;
    background-color: $color-secondary;
    z-index: 1000;
    border-radius: 0;

    .modal-title {
      font-size: 18px;
      font-weight: 500;
    }

    &.back-icon {
      margin-right: 10px;

    }
  }

  .modal-header-back {
    justify-content: left;
  }

  .product-item-description {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    border: 0;
  }

  .product-item-img {
    //position: sticky;
    padding: 16px;
    z-index: 1000;
    opacity: 1;
    //top: 0px;
    width: 100%;

    img {
      border-radius: 10px;
      width: 100%;
      object-fit: cover;
      //height: 80vw;
    }
  }

  .product-item {
    legend {
      margin-bottom: 4px;
      font-size: 14px;
      font-weight: 400;
      ul {
        margin-bottom: 4px;
        display: flex;
        list-style: none;
        padding-left: 0;
        li {
          margin-right: 8px;
        }

        li::before {
          content: "\2022"; /* Unicode bullet */
          margin-right: 8px; /* Adjust spacing between bullet and text */
        }
        li:first-child::before {
          content: none;
        }
      }
    }
    h5 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 4px;
      color: $color-text-primary;
    }

    .product-modifier-option .btn-pick {
      margin-bottom: 10px;
    }

    .product-item-modifiers {
      padding-left: 0px;
      padding-right: 0px;
    }

    .product-item-modifiers-errors {
      margin-bottom: 0.5rem;
      color: $color-red;
    }

    .product-modifier-option-pick {
      margin-bottom: 10px;

      label {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border: 1px solid $color-border;
        border-radius: 8px;
        line-height: 1.3;
        padding: 8px;
        cursor: pointer;
        flex-direction: column;
        text-align: center;
        font-size: 14px;
        width: 100%;
        height: 100%;
        font-weight: 500;
        color: $color-text-primary;
        .price {
          color: $color-text-default;
        }

        span {
          font-size: 14px;
          font-weight: 400;
          display: block;
          text-align: center;
          margin-left: 0px !important;
        }
      }

      input {
        display: none;
      }

      input:checked + label {
        color: #fff;
        background-color: var(--brand-500);

        span {
          color: #fff;
        }
      }
    }

    .product-item-footer {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .btn-submit {
      height: 48px;
      padding: 4px;
      font-weight: 500;
      line-height: 18px;

      .btn-cart-price {
        font-weight: 300;
        font-size: 14px;
        .price {
          color: inherit;
        }
      }
    }
  }

  .product-option-with-image {
    background-color: #fff;
    padding: 10px;
    border: 1px solid $color-border;
    border-radius: 8px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;

    &.error {
      border-color: $color-red;
    }

    .check-circle {
      svg > path {
        fill: var(--brand-500);
      }

      color: var(--brand-500);
      display: none;
      position: absolute;
      left: 5px;
      top: 5px;
    }

    .product-option-multilevel {
      margin-top: 12px;

      p {
        margin-bottom: 6px;
      }

      &.d-flex, .d-flex {
        .btn {
          margin-right: 6px;
        }

        .btn:last-child {
          margin-right: 0px;
        }

        .last {
          margin-left: auto;
        }
      }
    }

    .product-option-errors {
      margin-top: 10px;
    }

    &.active {
      border-color: var(--brand-500);

      .check-circle {
        display: block;
        z-index: 1;
      }
    }

    .card-img {
      width: 70px;
      height: 70px;
      margin-right: 10px;
      flex-shrink: 0;
      margin-bottom: 0px;

      img {
        border-radius: 10px;
        max-width: 100%;
      }

      svg, path {
        margin: 10px;
        width: 50px;
        height: 50px;
      }
    }

    h4 {
      font-size: 14px;
    }

    .product-option-body {
      flex: 1 1;
      display: flex;
      position: relative;
    }

    .product-option-content {
      position: relative;
      flex: 1;
      justify-content: center;
      flex-direction: column;
      display: flex;

      p {
        font-size: 14px;
        line-height: 18px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* Limit to two lines */
        overflow: hidden;
        text-overflow: ellipsis; /* Add ellipsis for overflowed text */
        white-space: normal; /* Wrap text within the specified number of lines */
      }
    }

    .product-option-actions {
      //position: absolute;
      //bottom:0px;
      //right: 0px;
      //left:0px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .price {
        font-size: 12px;
        font-weight: 500;

        small {
          color: $color-text-primary-light;
        }
      }
    }

    .product-option-quantity {
      margin-left: auto;

      input {
        padding-left: 3px;
        padding-right: 3px;
        border-color: #E8F1FF;
      }
    }
  }
}

.modal-full {
  &.modal-product-full {
    @media (min-width: 800px) {
      max-width: 800px;

      .modal-content {
        width: 100%;
      }
    }
  }
}

@media (min-width: 992px) {
  .modal-product-content {
    display: flex;
    .product-item-img {
      width:400px;
    }
    .modal-product-full-right {
      flex:1;
    }
  }
  .modal-product-full {
    .modal-content {
      width: 800px;
    }

    .modal-product-full-right {
      flex-direction: column;
      display: flex;
    }

    .product-item {
      display: flex;
      flex-direction: column;
      height: 100%;
      flex: 1;

      .product-item-content {
        flex: 1;
      }
    }

  }
}
