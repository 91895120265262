//.badge {
//   border-radius: 16px;
//  font-weight: 400;
//  font-size: 12px;
//}
.bg-success {
  background-color: $success !important;
}
.badge-danger-light {
  background-color: #FCF4F4;
  border: 1px solid #F7D1D1;
  color: $color-red;
  font-weight: 500;
  font-size: 11px;
}
.badge-success-light {
  color: #1C793F;
  //border-radius: var(--corner-radius-s, 6px);
  border-color: #BFF3D1;
  background: #F1FCF4;
  font-weight: 500;
  font-size: 12px;
}

.badge {
  
}
.badge-light {
  border: 1px solid #E2E8F0;
  background-color: $color-secondary;
  color: $color-text-default;
}