.cover-new {
  width: 100%;
  max-height: 200px;     
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 16px;
  img {
    width: 100%;
    height: 100%;         
    object-fit: cover;
    object-position: center;
  }
}
.cover-header {
  padding-bottom: 16px;
  h1 {
    font-weight: 500;
    font-size: 24px;
  }
  .badge-container {
    margin-bottom: 16px;
  }
  .badge {
    font-size: 14px;
    font-weight: 400;
    padding: 6px 12px;
    border:1px solid $color-grey-super-light;
    color: $color-text-primary;
  }
}

@media (min-width: 768px){
  .cover-new {
    max-height: 400px;
  }
}

@media (min-width: 992px){
  .cover-new {
    max-height: 400px;
  }
  .cover-header {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}