.form-group {
  margin-bottom: 1rem;
}

.form-group input.form-control, .form-group select.form-control {
  display: inline-block;
  padding: 0px 8px 0px 12px;
  background: #fff;
  -webkit-appearance: none;
  width: 100%;
  font-size: 14px;
  height: 50px;
  font-weight: 300;
  z-index: 1;
  position: relative;
  outline-offset: 0px;
  color: #000;
  border-radius: 6px;

}

.form-group select.form-control {
  -webkit-appearance: revert;
  padding-left: 8px;
}

.form-group {
  position: relative;
}

.form-group .form-control + label, .form-group .search-input + label, .form-group .form-control + label, .form-group .search-input + label {
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: normal;
  color: $color-grey-light;
  position: absolute;
  top: 3px;
  left: 60px;
  opacity: 0;
  z-index: 5;
  transition: all .4s ease;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 30px);
}

.main {
  .form-control {
    &:focus {
      border-color: var(--brand-500);
      box-shadow: none;
    }
  }

  .form-check > input:checked {
    background-color: var(--brand-500);
    border-color: var(--brand-500);
  }
}

input.form-control:focus::-webkit-input-placeholder, textarea.form-control:focus::-webkit-input-placeholder, select.form-control:focus::-webkit-input-placeholder {
  opacity: 0;
}

.search-input:focus::-webkit-input-placeholder {
  opacity: 0;
}

.form-group .search-input {
  padding-bottom: 0px;
}

.form-group.focus {
  label {
    opacity: 1;
    left: 13px;
    //transform: translate(0, 0) scale(1);
    cursor: pointer;
  }
}

input.form-control:not(:placeholder-shown) + label,
input.form-control:focus + label,
textarea.form-control:not(:placeholder-shown) + label,
textarea.form-control:focus + label,
select.form-control:not(:placeholder-shown) + label,
select.form-control:focus + label {
  opacity: 1;
  left: 13px;
  //transform: translate(0, 0) scale(1);
  cursor: pointer;
}

.form-row {
  margin-right: -5px;
  margin-left: -5px;

  > .col, > [class*=col-] {
    padding-right: 5px;
    padding-left: 5px;
  }

  input.form-control:not(:placeholder-shown) + label,
  input.form-control:focus + label,
  textarea.form-control:not(:placeholder-shown) + label,
  textarea.form-control:focus + label,
  select.form-control:not(:placeholder-shown) + label,
  select.form-control:focus + label {
    left: 19px;
  }

  .form-group.focus label {
    left: 19px;
  }
}

.form-group.input-group > .form-group-container {
  flex: 1 1 auto;
  width: 1%;
  position: relative;
}

.form-group.input-group > .form-control {
  width: 1%;
}

.form-group .input-group > .form-control, .form-group .input-group > .custom-select, .form-group .input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  height: calc(2.0625rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
}

.form-group .input-group-lg > .form-control, .form-group .input-group-lg > .custom-select, .form-group .input-group-lg > .custom-file {
  height: calc(2.640625rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
}

.form-group .input-group-sm > .form-control, .form-group .input-group-sm > .custom-select, .form-group .input-group-sm > .custom-file {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0px 8px 0px 12px;
  height: auto;
}

label.required:before {
  content: "* ";
}


.input-single {
  display: flex;
  padding: 9px;
  margin-bottom: 0px;

  &.disabled {
    opacity: 0.65;
  }
  input {
    width: 20px;
    height: 20px;
    margin-right: 11px;
  }

  span {

  }
}

.body {
  .was-validated .form-control:invalid, .form-control.is-invalid {
    border-color: #dc3545;
  }

  .form-group {
    .was-validated .form-control:invalid, .form-control.is-invalid {
      border-color: #dc3545;
    }
  }
}


.search-input {
  input {
    width: 100%;
    border-radius: 0px;
  }

  .close {
    z-index: 999;
    font-size: 15px;
    margin-top: 0px;
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;

    span {
      display: none;;
    }
  }

  .powered-by-google {
    position: absolute;
    bottom: $padding-default;
    right: $padding-default;
    //margin-top:$padding-sm;
    //padding-top:$padding-sm;
    //border-top: 1px solid $color-border;
    //text-align: center;
    img {
      height: 14px;
    }
  }
}


//FORMS Types
.form-types {
  h5 {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    //justify-content: center;
    svg {
      height: 16px;
      width: 16px;
      margin-right: 6px;
    }

    .btn {
      margin-left: auto;
    }
  }

  .form-section {
    margin-bottom: 10px;

    .search-input-basic {
      padding-bottom: 1rem;
    }
  }

  .btns {
    margin-bottom: 5px;

    .row {
      margin-right: -5px;
      margin-left: -5px;

      > .col, > [class*=col-] {
        padding-right: 5px;
        padding-left: 5px;
        margin-bottom: 10px;

        .btn {
          padding-left: 4px;
          padding-right: 4px;
          font-size: 12px;
        }
      }
    }
  }
}


.input-group-inside {
  > :not(:last-child) {

  }
}
.input-search {
  position: relative;
  > input {
    padding-left: 3rem;
  }
  svg,path {
    fill: $color-default-light;
  }
  .left, .right {
    margin-left: 1rem;
    margin-right: 1rem;
    position: absolute;
    top:50%;
    transform: translateY(-50%) !important;
  }
  .left {
    left:0;
  }
  .right {
    right:0;
  }
  svg {
    height:20px;
    width:20px;
  }
}
.form-group-buttons {
  position: relative;
  .form-group-button {
    position: absolute;
    right:0px;
    top:0px;
    bottom: 0px;
    padding:8px;
    display: flex;
    align-items: center;
    z-index: 1;
  }
}

textarea.form-control {
  padding: 1rem;
}