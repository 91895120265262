.voucher-card {
  .card-content {
    border-radius: 10px;
    margin-left: 16px;
  }

  .card-body {
    color: #fff;
    background: linear-gradient(232deg, var(--neutral-400, #94A3B8) 5.88%, var(--neutral-600, #526077) 54.45%);
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    align-items: center;
  }

  .card-img {
    transform: rotate(-2deg);
    width: 95px;
    height: 95px;
    margin-left: -16px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #94A3B8;
    background: #64748B;
    display: flex;
    margin-right: 16px;

    svg, path {
      fill: #fff;
    }

    svg {
      width: 32px;
      height: 32px;
      transform: rotate(-2deg);
    }
  }

  &.success {
    .card-body {
      background: linear-gradient(232deg, var(--positive-400, #54D483) 5.88%, var(--positive-600, #20994D) 54.45%);
    }

    .card-img {
      border-color: #54D483;
      background: #2DB960;
    }
  }

  .card-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
  }

  .card-text {
    font-size: 14px;
    font-weight: 400;
  }

  .card-footer {
    align-items: center;
    border: 1px solid $color-border;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #fff;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    .valid-time {
      display: flex;
      align-items: center;
      svg {
        margin-right: 4px;
        width:12px;
        height:12px;
      }
    }
  }


  //background-color: #fff;
  //border-radius: 6px;
  ////padding: 10px;
  //text-align: center;
  //
  //.card-img {
  //  height: 170px;
  //  width: 100%;
  //  padding: 8px;
  //  position: relative;
  //
  //  img {
  //    max-height: 100%;
  //    max-width: 100%;
  //    object-fit: cover;
  //    border-radius: 6px;
  //  }
  //
  //  .card-img-color {
  //    background-color: #7C3AED;
  //    width: 100%;
  //    height: 100%;
  //    border-radius: 6px;
  //    display: flex;
  //    justify-content: center; /* Centers horizontally */
  //    align-items: center;
  //
  //    svg {
  //      width: 35%;
  //      height: 35%;
  //      transform: rotate(-30deg); /* Rotate 45 degrees */
  //      fill: #fff;
  //      //fill: currentColor;       /* Inherit color from parent */
  //      opacity: 0.5;
  //
  //      path {
  //        fill: #fff;
  //        //fill: currentColor;       /* Inherit color from parent */
  //      }
  //    }
  //  }
  //
  //  .valid-time {
  //    background-color: rgba(0, 0, 0, 0.7);
  //    border-radius: 6px;
  //    padding: 5px;
  //    position: absolute;
  //    bottom: 20px;
  //    left: 0px;
  //    right: 0px;
  //    margin-left: 20px;
  //    margin-right: 20px;
  //    color: #fff;
  //  }
  //
  //  .points {
  //    background-color: #fff;
  //    border-radius: 6px;
  //    padding: 6px;
  //    position: absolute;
  //    top: 16px;
  //    right: 16px;
  //    font-size: 12px;
  //    font-weight: 500;
  //  }
  //}
  //
  //.card-body {
  //  padding: 8px;
  //}
  //
  //.card-footer {
  //  background-color: #fff;
  //  border: none;
  //  padding: 8px;
  //}
  //
  //.card-title {
  //  font-size: 16px;
  //  font-weight: 500;
  //}
  //
  //.btn {
  //  width: 100%;
  //}

}