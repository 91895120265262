.offcanvas-header {
  img {
    height:24px;
  }
}
.offcanvas-body {
  h4 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  ul {
    margin-bottom: 24px;
  }
  .nav-item {
    border: 1px solid #E4E4E4;
    margin-bottom: 8px;
    border-radius: 6px;
  }
  .nav-link {
    display: flex;
    padding: 10px 16px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    min-height:52px;
    width: 100%;
    text-align: left;
    cursor: pointer;

    > span {
      margin-right: 12px;
    }
    > svg:last-child {
      margin-left: auto;
      fill: #2A2A2A;
      path {
        fill: #2A2A2A;
      }
    }
  }
  .nav-link-multiline {
    flex: 1;
    color: $color-text-primary;
    small {
      color:$color-text-default;
      font-size: 12px;
      font-weight: 400;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2; 
      -webkit-box-orient: vertical;
      overflow: hidden
    }
  }

  .open-hours, .drawer-languages {
    padding: 16px;
  }

  .drawer-languages {
    ul {
      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;

        p {
          font-weight: 500;
          color: $color-default-dark;

          &:hover {
            color: var(--brand-500);
          }
        }

        svg {
          margin-left: auto;

          path {
            fill: var(--brand-500);
          }
        }
      }
    }
  }

  .register-btn {
    .rounded-icon {
      background-color: #F1FCF4;
      svg, path {
        fill: #28A745;
      }
    }
  }
}
.btn-gift {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F1FCF2;
  svg, path {
    fill: #28A745;
  }
}