$color-font-light: #CCD5E0;
$color-font-dark: #94A3B8;
$color-bg-light:#171E2F;
$color-bg-lighter:#1F293A;
$color-bg-dark: #101729;
$color-bg-darker: #0D1326;
$color-border: #334155;

$color-danger: #E78282;
$color-success: #54D483;
$color-warning: #E59552;

html:has(.dark-mode) {
  background-color: $color-bg-dark;

  .modal-content {
    background-color: $color-bg-dark;
    > div {
      background-color: $color-bg-dark;;
    }
    .modal-header {
      background-color: $color-bg-dark;
      color: $color-font-light;
    }

    .modal-footer {
      background-color: $color-bg-dark;
    }

    table {
     td {
       background-color: $color-bg-light;

       svg, path {
         fill: var(--brand-500)
       }
     }
    }
  }

  .modal-product-full-right {
    background-color: $color-bg-light;

    .modal-header {
      background-color:  $color-bg-light;
    }

    .product-modifier-option1 {
      .btn-icon {
        &:hover {
          background-color: $color-bg-dark;
        }
      }
    }
  }

  .modal-product-full {
    background-color: $color-bg-dark;
  }

  .modal-product-full, .modal-product-full-right {
    h5 {
    color: $color-font-light;
    }

    .product-item {
      label {
        background-color: $color-bg-light;
        border-color: $color-border;
        color: $color-font-light;

        span {
          color: $color-font-dark;
        }
      }

      &-footer {
        background-color: $color-bg-dark;
      }
    }

    .product-option-with-image {
      border-color: $color-border;
      background-color: transparent;
    }
  }
}

.dark-mode {
  h1, h2, h3, h4, h5, h6 {
    color: $color-font-light;
  }

  h2 > svg {
    path {
      fill: $color-font-light;
    }
  }

  p, small {
    color: $color-font-dark;
  }

  strong {
    color: $color-font-light;
  }

  .text-success {
    color: $color-success !important;
  }

  .text-danger {
    color: $color-danger !important;
  }

  .App {
    background: $color-bg-dark;
  }

  .icon-square {
    background-color: #1E293A;
  }

  .card-advert strong {
    color: $color-success;
  }

  .cart-card {
    &-info {
      border-color: $color-border;
      dt {
        color: $color-font-dark;
      }

      dd {
        color: $color-font-light;
      }
    }
  }

  .bg-success {
    background-color: $color-success !important;
  }

  .cart-progress {
    &-info {
      div {
        color: $color-font-light;
      }

      svg, path {
        fill: $color-font-light;
      }

      &.text-success {
        div {
          color: $color-success;
        }

        svg, path {
          fill: $color-success;
        }
      }
    }

    .progress {
      background-color: $color-bg-light;

      &-bar {
        background-color: $color-font-dark;
      }
    }
  }

  .form-group input.form-control, .form-group select.form-control, .form-control {
    background-color: transparent;
    color: $color-font-light;
    border-color: $color-border;

    &::placeholder {
      color: $color-font-dark;
    }
  }

  .horizontal-divider  {
    span {
      background-color: $color-bg-dark;
      color: $color-font-dark;
    }

    &:before, &:after {
      border-color: #324155;
    }
  }

  .input-group {
    .input-group-prepend, .input-group-append, .input-group-text {
      color: $color-font-light;

      svg, path {
        fill: $color-font-light;
      }
    }
  }

  .btn-promotion {
    background-color: transparent;
    border: 1px solid $color-border;

    .content {
      color: $color-font-light;
    }

    &.active, &:hover, &:focus {
      background-color: $color-bg-light;
      border-color: unset;
    }
  }

  .btn-outline-danger {
    border-color: $color-border;
   }

  .btn-outline-secondary {
    color: $color-font-light;

    &.btn {
      &:hover, &:focus, &:active {
        background-color: $color-bg-light;
      }
    }
  }

  .rounded-icon {
    background-color: $color-bg-lighter;

    svg, path {
      fill: $color-font-dark;
    }
  }

  .loader {
    border-color: $color-border;
    border-top-color: var(--brand-500);
  }

  .badge {
    border-color: $color-border;
    color: $color-font-light;
    font-weight: 500;

    &-light {
      background-color: $color-bg-light;
    }

    &-success-light {
      color: $color-success;
      background-color: $color-bg-lighter;
      border: 1px solid $color-border;
    }
   }

  .alert-danger {
    color: $color-danger;
  }

  .alert-success {
    color: $color-success;
  }

  .alert-warning {
    color: $color-warning;
  }

  .alert {
    background-color: #1E293A;

    .btn-default {
      background-color: $color-bg-dark;
      color: $color-font-light;

      svg > path {
        fill: $color-font-light;
      }
    }
  }

  .navbar {
    background: $color-bg-dark;

    svg, path {
      fill: $color-font-dark;
    }
  }

  .navbar-form {
    border-top-color: $color-border;
  }

  .navbar .navbar-nav .nav-link {
    color: $color-font-dark;
  }

  .navbar .navbar-brand, .navbar-actions {
    color: $color-font-dark;
  }

  .navbar-back {
    border-color: $color-border;

    a {
      color: $color-font-dark;
    }

    path, svg {
      fill: $color-font-dark;
    }
  }

  .drawer {
    .nav-item > .register-btn {
      .rounded-icon {
        background-color: $color-bg-lighter;
        svg, path {
          fill: $color-success;
        }
      }
    }

    .drawer-languages {
      ul {
        li {
          p {
            color: $color-font-light;

            &:hover {
              color: var(--brand-400);
            }
          }

          svg {
            path {
              fill: var(--brand-400);
            }
          }
        }
      }
    }
  }

  .btn-default {
    color: $color-font-dark;
    border-color: $color-border;
    background-color: transparent;

    svg, path {
      fill: $color-font-dark;
    }

    &:hover {
      color: $color-font-dark;
      background-color: $color-bg-light;
    }
  }

  .cover-new {
    border: 1px solid $color-border;
  }

  .container-menu-picker .nav-link.active {
    background-color: transparent;
    color: $color-font-dark;;
    border: 1px solid $color-border;
  }

  .container-menu-picker .btn-choose-menu {
    background-color: transparent;
    color: $color-font-dark;
    border: 1px solid $color-border;

    .menu-item-content {
      background-color: transparent;
      color:$color-font-dark;
    }
  }

  .menu-item {
    .card-title {
      color: $color-font-light;
    }
  }

  .nav-tabs-top {
    .nav-item.active .nav-link {
      border-bottom-color: $color-border;
    }
  }

  .menu .menu-category h4, .menu .menu-category .h4 {
    color: $color-font-dark;
  }

  .sticky-outer-wrapper .nav-categories-wrapper {
    background-color: transparent;

      .btn-icon {
        background-color: $color-bg-dark;
      }
  }

  .menu-category {
    svg > path {
      fill: $color-font-dark;
    }
  }

  .menu .menu-items .card {
    background-color: transparent;
    border-color: $color-border;

    &:hover {
      background-color: $color-bg-light;
    }
  }

  .menu .sticky-inner-wrapper {
    background-color: $color-bg-dark;
  }

  .card-title {
    color: $color-font-light;
  }

  .footer {
    &-store {
      background-color: $color-bg-light;
    }

    &-bottom {
      .footer-powered-by {
        color: $color-font-dark;
      }
    }

    &-info {
      border-top-color: $color-border;
    }
  }

  .cart-sticky {
    background-color: $color-bg-light;

    &-container {
      border: 1px solid $color-border;
      background-color: $color-bg-dark;

      .cart-sticky-header {
        border-bottom-color: $color-border;
        svg > path {
          fill: $color-font-light;
        }
      }
    }

    &-footer {
      border-top-color: $color-border;
    }
  }

  .cart-summary {
      background-color: $color-bg-light;

      dt {
        color: $color-font-dark;
      }

      dd {
        color: $color-font-light;
      }

      .separator {
        border-top-color: $color-border;
      }
  }

  .cart .card {
    border-color: $color-border;
  }

  .cart-item {
    border-color: $color-border;

    &-multilevel {
      color: $color-font-dark;
    }

    &-description {
      strong {
        color: $color-font-dark;
      }
    }

    &-body {
      .cart-item-description {
        color: $color-font-dark;
        .order-comment {
          background-color: $color-bg-light;
          color: $color-font-dark;
        }
      }

      ul > li {
        color: $color-font-dark;
      }
    }
  }

  .card-cart-header {
    color: $color-font-dark;
  }

  .card-cart-body {
    .btn-check-full {
      color: $color-font-light;

      svg, path {
        fill: $color-font-light;
      }

      &:hover, &:focus {
        border-color: $color-border;
        background-color: $color-bg-light;
      }

      &.active {
        border-color: var(--brand-500 );
      }
    }

    .btn-groups-columns {
      small {
        color: $color-font-dark;
      }

      .btn-default {
        color: $color-font-light;

        svg, path {
          fill: $color-font-light;
        }
      }
    }
  }

  .cart-no-items-body {
    .circle-container {
      .circle {
        background: linear-gradient(to bottom, rgba(51, 65, 85, 0.55) 0%, rgba(51, 65, 85, 0) 69.37%);
      }

      .center-svg {
        border-color: $color-bg-lighter;

        svg, path {
          fill: $color-font-dark;
        }
      }

      + p {
        color: $color-font-dark;
      }
    }
  }

  .input-group-quantity {
    .input-group-text {
      border-color: #262626;
    }

    .btn-secondary {
      background-color: #262626;
      border-color: #262626;
      color: #2CB7FF;

      svg, path {
        fill: #2CB7FF;
      }
    }
  }

  .menu-filters {
    .btn-default {
      border-color: $color-border;
      background-color: $color-bg-dark;
      color: $color-font-dark;

      &.active {
        background-color: $color-bg-light;
        color: $color-font-light;
      }

      &:hover, &:focus {
        background-color: $color-bg-light;
      }

      .badge-danger-light {
        background-color: $color-bg-light;
        color: $color-font-light;
      }
    }
  }

  .nav-scrolling-buttons-wrapper {
    background-color: transparent;

    .nav-tabs-top {
      background-color: transparent;

      .nav-item {
        font-weight: 500;

        a {
          color: $color-font-dark;

          &:hover {
            color: $color-font-light;
          }
        }

        &.active > a {
          color: $color-font-light;
          border-bottom-color: $color-font-light;
        }
      }
    }
  }

  .sticky-outer-wrapper.is-sticky {
    .nav {
      box-shadow: inset 0 -3px 0  $color-bg-light;
    }
  }

  .multistore-places {
    background-color: $color-bg-light;

    .card-place {
      background-color: $color-bg-dark;

      .card-header {
        border-bottom-color: $color-border;

        &-text {
          span {
            color: $color-font-dark;
          }
        }
      }

      .icon-list {
        li {
          svg, path {
            fill: $color-font-dark;
          }

          ul {
            color: $color-font-dark;
          }
        }
      }

      .btn-default {
        color: $color-font-light;

        svg, path {
          fill: $color-font-light;
        }
      }

      &-navigate {
        svg, path {
          fill: $color-font-light;
        }
      }
    }
  }

  .multistore-location {
    .btn-outline-primary {
      background-color: $color-bg-dark;
      border-color: var(--brand-500);

      &:focus, &:hover {
        color: unset;
      }
    }
  }

  .nav {
    .nav-item  {
      .nav-link {
        color: $color-font-dark;
      }

      &.active {
        .nav-link {
          color: $color-font-light;
          border-bottom-color: $color-font-light;
        }
      }
    }
  }

  .offcanvas {
    background-color: $color-bg-dark;

    .nav-item {
      border-color: $color-border;
      .nav-link {
        svg, path {
          fill: $color-font-light;
        }

        &:hover, &:focus {
          background-color: $color-bg-light;
        }
      }

      .nav-link-multiline {
        color: $color-font-light;

        small {
          color: $color-font-dark;
        }
      }
    }
  }

  .dropdown-toggle {
    &.show, &.btn:active {
      border-color: $color-border;
    }

    &.btn {
      color: $color-font-dark;
    }
  }

  .page {
    .header-container {
      background-color: $color-bg-light;

      h1 {
        color: $color-font-dark;
      }
    }

    &.rules-component {
      p, h4 {
        color: $color-font-light;
      }
    }
  }

  .user {
    background-color: $color-bg-dark;

    .nav-link {
      background-color: $color-bg-dark;
      border-color: $color-border;
    }

    &-header-top, .top {
      border-bottom-color: $color-border;
    }

    &-menu {
      h3 {
        color: $color-font-dark;
      }

      .nav-item > .nav-link {
        background-color: $color-bg-darker;
        color: $color-font-light;

        &:hover, &:focus {
          background-color: $color-bg-light;
        }
      }
    }

    &-info {
      background-image: url("../../images/backgrounds/logged-user-dark-background.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }

    &-info-body {
      h5 {
        color: #fff;
      }

      .user-info-qrcode > img {
        border-radius: 4px;
      }

      .alert-warning {
        background-color: $color-bg-lighter;
        border-color: $color-border;
      }

      .user-info-boxes {
       li > .nav-link {
         color: $color-font-light;

          svg:first-child > path {
            fill: $color-success;
          }

         strong {
           color: $color-success;
         }

         &:hover, &:focus {
           background-color: $color-bg-light;
         }
        }
      }
    }

    &-vouchers {
      h5 {
        color: $color-font-dark;
      }

      .user {
        background-color: $color-bg-dark;
      }
    }
  }

  .voucher-card {
    .card-title, .card-text {
      color: #fff;
    }

    .card-footer {
      background-color: $color-bg-dark;
      border-color: $color-border;

      .valid-time {
        color: $color-font-dark;

        svg, path {
          fill: $color-font-dark;
        }
      }
    }
  }

  .voucher-modal-body {
    .rounded-icon {
      background-color: $color-bg-lighter;

      svg > path {
        fill: $color-success;
      }
    }

    .voucher-full-qrcode {
      canvas {
        margin-top: 0;
      }
    }

    .voucher-full-qrcode > .info {
      color: $color-font-light;
      margin-top: 1rem;
    }
  }

  .navigate-bottom {
    background-color: $color-bg-dark;
  }

  .store-info-modal {
    table {
      td {
        color: $color-font-light;
      }

      ul {
        li > strong {
          color: $color-font-dark;
        }

        li {
          color: $color-font-light;
        }
      }
    }
  }

  .summary-loading-new {
    .circle-container {
      .center {
        .circle {
          background: linear-gradient(to bottom, rgba(51, 65, 85, 0.55) 0%, rgba(51, 65, 85, 0) 69.37%);
        }
      }
    }

    .center-svg {
      border-color: $color-bg-lighter;
    }

    &.warning {
      h3 {
        color: $color-warning;
      }

      svg, path {
        fill: $color-warning;
      }
    }

    &.success {
      h3 {
        color: $color-success;
      }

      svg, path {
        fill: $color-success;
      }
    }

    &.danger {
      h3 {
        color: $color-danger;
      }

      svg, path {
        fill: $color-danger;
      }
    }

    .summary-response {
      &-reason {
        color: $color-font-light;
      }

      &-box {
        border-color: $color-border;
      }

      dl {
        color: $color-font-dark;
      }

      dd {
        color: var(--brand-400);
      }
    }
  }

  .summary-container {
    .btn-default {
      &:focus-visible, &:active {
        border-color: $color-border;
      }
    }
  }


  .cart-vouchers {
    svg, path {
      fill: $color-font-dark;
    }

    .empty {
      cursor: pointer;
      svg, path {
        fill: var(--brand-500);
      }

      p {
        color: var(--brand-500);
      }
    }

    svg {
      min-width: 20px;
      min-height: 20px;
    }

    span {
      cursor: pointer;
      color: var(--brand-500);
      margin-left: 1rem;
    }
  }

  .promotions {
    background-color: #171E30;
    .btn-promotion {
      background-color: $color-bg-dark;
      border-color: $color-border;

      .icon-container {
        background-color: $color-bg-lighter;

        svg > path {
          fill: $color-success;
        }
      }

      &:active, &:focus, &:hover {
        background-color: $color-bg-light;
      }
    }

    .navigate-left, .navigate-right {
      .btn {
        background-color: $color-bg-dark;

        &:active, &:hover, &:focus {
          background-color: $color-bg-light;
          border-color: $color-border;
        }
      }
    }
  }

  .promotion-info-modal {
    dt {
      color: $color-font-light;
    }

    dd {
      color: $color-font-dark;
    }
  }

  .pick-up-date-modal {
    &-section {
      svg > path {
        fill: $color-font-dark;
      }

      h5 {
        color: $color-font-dark;
      }
    }
  }

  .multistore-full, .multistore-list {
    .card-place {
      background-color: $color-bg-dark;

      .card-header {
        border-bottom-color: $color-border;

        &-text {
          span {
            color: $color-font-dark;
          }
        }
      }

      li {
        color: $color-font-dark;

        svg, path {
          fill: $color-font-dark;
        }
      }

      .btn-default {
        color: $color-font-light;

        svg, path {
          fill: $color-font-light;
        }
      }

      &-navigate {
        svg, path {
          fill: $color-font-light;
        }
      }
    }
  }

  .multistore-map {
    .marker-place-icon {
      background-color: $color-bg-dark;
    }

    .leaflet-control-zoom {
      border-color: $color-border;

      a {
        background-color: $color-bg-dark;
        border-color: $color-border;
        color: $color-font-light;
      }
    }

    &-controls {
      .btn-default {
        background-color: $color-bg-dark;
        border-color: $color-border;

        &:hover, &:focus, &:active {
          background-color: $color-bg-light;
        }
      }
    }
  }

  .multistore-full-list {
    background-color: $color-bg-light;

    .multistore-list-content {
      background-color: $color-bg-light;

      .nav-link {
        color: $color-font-dark;

        &:hover {
          color: $color-font-light;
        }
      }
    }
  }

  .multistore-list {
    background-color: $color-bg-dark;

    &-content {
      background-color: $color-bg-light;
    }

    &-navs {
      background-color: $color-bg-dark;

      .nav-item {
        .nav-link {
          color: $color-font-dark;
        }

        &.active {
          border-bottom-color: $color-font-light;
        }
      }
    }

    &-controls {
      .btn-default {
        background-color: $color-bg-dark;
      }
    }
  }
}