
.alert {
  border-color: transparent;
  font-weight: 400;

  .btn {
    display: flex;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.alert-danger {
  background: #FCF4F4;
  color: #A53030;
}
.alert-warning {
  background: #FDF7EF;
  color: #AD4B21;
}

.alerts {
  position: fixed;
  left: 0;
  right: 0;
  top: 0px;
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  z-index: 1051;

  .alert {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 10px;
  }
}