.menu-page {
  .menu-advert {
    margin-bottom: 32px;
  }

  h2 {
    font-size: 18px;
    font-weight: 500;
    align-items: center;
    display: flex;

    svg {
      margin-right: 12px;
    }
  }

  //.menu-filters {
  //  margin-bottom: 12px;
  //}
  .menu-filters-1 {
    > div {
      margin-bottom: 12px;
    }

    padding-bottom: 16px;
  }

  .menu-filters.input-search {
    margin-bottom: 8px;
  }
}

.navigate-bottom {
  display: none;
}

@media (min-width: 992px) {

  .navigate-bottom {
    position: fixed;
    bottom: 12px;
    right: 432px;
    z-index: 1001;
    display: block;
    .btn {
      box-shadow: 0px 2px 8px 0px rgba(7, 12, 34, 0.15);
    }
  }
}

.menu .sticky-inner-wrapper {
  background-color: #ffffff;
}

.sticky-outer-wrapper.is-sticky {
  .nav-categories {
    box-shadow: inset 0 -3px 0 #E4E4E4;
    background-color: #fff;

    &-wrapper {
      background-color: #fff;
    }
  }
}

//menu
.menu {
  .languages {
    margin-bottom: 20px;
    margin-top: 20px;
    display: none;
  }
}

.menu-item {
  color: $color-text-primary;
  text-decoration: none;

  &:hover .card, .card:hover {
    border-bottom: 1px solid var(--brand-500);
    cursor: pointer;
  }

  .card {
    &.disabled {
      opacity: .65;
    }

    border: 0px;
    border-bottom: 1px solid $color-border;
    border-radius: 0px;
    flex: 1;
    display: flex;
    flex-direction: inherit;
    padding: 0px 0px 16px 0px;
    margin-bottom: 16px;

    .card-body {
      position: relative;
      padding: 0px;
      height: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      flex: 1;
      display: flex;

      .card-text {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .card-title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .prices {
        display: flex;
        align-items: center;

        .price {
          margin-right: 5px;
        }
      }

      .price-bottom {
        margin-top: auto;
      }

      .price {
        color: var(--brand-500);
        font-weight: 500;
        font-size: 16px;
        //margin-bottom: 5px;
      }

      .price-discount {
        color: #526077;
        text-decoration: line-through;
        font-weight: 300;
        font-size: 14px;
      }

      .tags {
        margin-bottom: 5px;

        .badge {
          white-space: wrap;
        }
      }
    }

    .card-img {
      width: 88px;
      height: 88px;
      margin-right: 12px;
      flex-shrink: 0;

      img {
        border-radius: 10px;
        width: 88px;
        height: 88px;
        object-fit: cover;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    h5 {
      color: $color-text-primary;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 4px;
    }

    .card-text {
      font-weight: 400;
      font-size: 12px;
      margin-bottom: 5px;
    }
  }
}

.menu {
  .menu-items-wrapper {
    padding-top: 32px;
  }

  .menu-category {
    width: 100%;
    //margin-bottom: 16px;
    h4 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 16px;
      color: $color-text-default;

      svg {
        margin-right: 5px;
      }
    }
  }

  .menu-promotions-category-container {
    padding-top: 32px;
  }

  .menu-items-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -7px;
    margin-left: -7px;

    .menu-item {
      display: flex;
      -webkit-box-flex: 0;
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 7px;
      padding-left: 7px;
    }
  }

  .btn-search-icon {
    width: 56px;
  }
}

@media (min-width: 992px) {
  .menu-items-wrapper {
    margin-bottom: 36px;
  }
  .menu-items-grid {
    .menu-item {
      @media (min-width: 992px) {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
  .menu-page {
    .btn-submit-bottom {
      display: none;
    }
  }
  .menu-item {
    display: flex;
    flex: 1 0 auto;

    &:hover .card, .card:hover {
      border: 1px solid var(--brand-500);
    }

    .card {
      width: 100%;
      border: 1px solid $color-border;
      border-radius: 10px;
      //align-items: center;
      padding: 16px;

      .card-body {
        padding: 0px;

        .card-text {
          line-height: 1.3;
        }

        .price {
          margin-bottom: 0px;
        }
      }

      .card-img {
        width: 95px;
        height: 95px;
        margin-right: 16px;

        img, svg {
          width: 95px;
          height: 95px;
        }
      }
    }
  }

}
