.navbar {
  padding: .5rem 0;
  background-color: #fff;

  .btn-choose-place {
    display: none;
    color: $color-text-primary;
    align-items: center;
    justify-content: center;
    text-align: left;
    height: 48px;

    h5 {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 14px;
    }

    small {
      display: -webkit-box;
      max-width: 240px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      font-weight: 400;
      font-size: 12px;
      color: $color-text-default;
    }
  }

  .navbar-brand {
    max-width: 150px;
    padding: 6px 0;
    margin: 0 1rem;
    text-align: left;
    
    .logo-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    img {
      height: 36px;
    }
  }

  .navbar-actions-container-desktop {
    display: none;
  }

  .navbar-cart-icon {
    svg {
      fill: var(--brand-500);
    }
  }

  form {
    width: 100%;

    > div {
      width: 100%;
    }
  }

  .navbar-brand-container {
    display: flex;
    width: 100%;
    flex-flow: wrap;
  }

  .navbar-actions-container {
    width: 100%;
  }

  input {
    border-radius: 0;
    height: 38px;
  }

  .navbar-options {
    margin-right: 4px;
    display: flex;
    margin-left: auto;
    align-items: center;

    .search-type {
      flex: 1 1 auto;

      button {
        width: 100%;
      }
    }

    .btn-icon {
      height: 48px;
      width: 48px;

      svg {
        height: 24px;
      }
    }

  }

  .btn-user {
    .dropdown-toggle:after {
      display: none;
    }
  }

  .navbar-toggler {
    border: none;
    width: 48px;
    height: 48px;
    display: initial;

    &:focus {
      box-shadow: none;
    }
  }

  .navbar-actions {
    display: flex;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
    align-items: center;

    > .btn {
      margin-left: auto;

      &:after {
        display: inline-block;
        margin-left: 10px;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
      }
    }
  }

  button.navbar-actions {
    border: none;
    background: transparent;
    text-align: left;
    padding: 0;
  }

  .languages {
    text-align: center;
    width: 100%;
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }

  .navbar-languages {
    .btn {
      color: #323232;

      svg {
        margin-right: 5px;
      }
    }

    .dropdown-item svg {
      margin-right: 7px;
    }
  }
}

.navbar-back {
  border-bottom: 1px solid $color-border;
  width:100%;
  margin-bottom: 16px;
  padding:0px;
  .btn-back {
    align-items: center;
    display: flex;
    padding-left: 8px;
    height: 48px;
    font-weight: 500;
    font-size: 18px;
    color: $color-default-dark;

    svg {
      margin-right: 8px;
      width: 24px;
      height: 24px;
    }
    svg, path {
      fill: $color-default-dark;
    }
  }

  .navbar-back-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h5 {
    font-size: 16px;
    margin-bottom: 0;
    color: $color-text-primary;
  }

  small {
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .navbar {
    .btn-choose-place {
      display: flex;
    }

    .navbar-brand-container {
      width: 100%;
      flex-flow: inherit;

      .navbar-menus {
        order: 2;

        .nav-link {
          color: $color-default;
          font-weight: 500;
        }
      }

      .navbar-actions-container {
        display: none;
        order: 1;
      }

      .navbar-options {
        order: 3;
      }
    }

    .navbar-actions-container-desktop {
      display: flex;
    }

    .navbar-actions {
      margin: 0;

      .content {
        order: 2;
      }

      .btn {
        order: 1;
        margin-right: 10px;
      }
    }

    .navbar-options {
      flex: none;
    }

    .navbar-nav {
      margin-left: auto;

      .nav-link {
        color: $color-text-primary;

        &:hover {
          color: var(--brand-500);
        }
      }
    }

    form {
      width: auto;

      > div {
        width: auto;
      }
    }

    .search-type, .btn-pick-date {
      margin-right: $padding-default;
    }

    .navbar-actions {
      width: auto;
      margin-top: 0;
      margin-bottom: 0;
    }

    .languages {
      text-align: left;
      width: auto;
    }
  }
}

.navbar-native {
  display: none;
}

.navbar-cart {
  .navbar-actions {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.navbar-brand {
  align-items: center;
  display: flex;
}

.navbar-actions a {
  font-weight: 500;
}