.footer {
  color: #fff;
  margin-bottom: 60px;

  .footer-store {
    background-color: #1F293A;
    padding: 24px;
    border-radius: 10px;
    //display: flex;
    //align-items: center;
    //flex-direction: column;
  }


  .footer-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 500;

    .footer-list-item {
      display: flex;
      border-bottom: 1px solid #334155;
      padding: 16px;
      align-items: center;

      &-action {
        cursor: pointer;
      }
    }

    > li:last-child .footer-list-item {
      border-bottom: none;
    }

    .btn-transparent {
      path, svg {
        fill: var(--brand-500);
      }
    }

    .footer-list-icon {
      margin-right: 12px;

      path, svg {
        fill: #83D9FF;
        width: 24px;
        height: 24px;
      }
    }

    .btn-icon {
      scg, path {
        fill: #fff;
      }
    }

    .footer-list-content {
      align-items: center;
    }

    .footer-list-action {
      margin-left: auto;
    }
  }

  .footer-bottom {
    text-align: center;
    padding: 24px;
  }

  .footer-powered-by {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: $color-text-default;

    span {
      margin-bottom: 5px;
    }

    img {
    }
  }

  .footer-social {
    padding-left: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    > li {
      margin-left: 16px;
      margin-right: 16px;

      svg, path {
        fill: #fff;
        width: 24px;
        height: 24px;
      }
    }
  }

  .footer-links {
    display: flex;
    align-items: center;
    justify-content: center;

    ul {
      list-style: none;
      padding-left: 0;
      display: flex;

      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }

  .footer-map {
    height: 210px;
    margin-bottom: 24px;

    .map-container {
      height: 100%; /* Take full height of the parent container */
      width: 100%; /* Optional, ensures it spans the width */
      border-radius: 6px;
    }
    svg, path {
      fill: var(--brand-500);
    }
  }
}

@media (min-width: 992px) {
  .footer .footer-store {
    padding: 24px 120px;
  }
}

.main {
  .footer {
    .footer-list-icon {
      svg, path {
        fill: var(--brand-300);
      }
    }
  }
}

.footer-languages {
  .languages-container {
    padding-left: 50px;
    gap: 8px;

    p {
      margin-bottom: 0;
      cursor: pointer;
      font-weight: 400;

      &.active {
        color: var(--brand-300);
      }

      &:hover {
        color: var(--brand-100);
      }
    }
  }
}