
.login-page {
  max-width: 700px;
  margin: 0 auto;
}

.login-box {
  display: flex;
  flex-direction: column;
  justify-content: center; /* align items vertically in the center */
  height: 100%; /* 100% of the viewport height */
  margin: 0;

  img {
    width: 160px;
  }

  h4 {
    text-align: center;
    font-size: 36px;
    margin-bottom: 40px;
    font-weight: 500;
  }

  .login-box-logo {
    text-align: center;
    margin-bottom: 40px;
  }

  .login-box-lost-password {
    text-align: right;
    text-decoration: none;
    margin-bottom: 1.3rem;
  }

  .login-box-register-info {
    hr {
      margin: 24px 0px 40px 0px;
      border: 1px solid $color-grey;
      border-width: 0 0 1px;
    }
  }
}

.modal-login {

  .modal-header {
    border-bottom: 0px;
  }

  .modal-footer {
    border-top: 0px;
  }

  .modal-title {
    flex: auto;
  }
}

.modal-user {
  .modal-header {
    .btn-back {
      border: 0px;
      background-color: transparent;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .modal-title {
    svg {
      margin-right: 12px;
      width: 24px;
      height: 24px;
    }
  }

  .modal-body {
    padding: 0;

    .background-shape {
      position: absolute;
      right: 0;

      path {
        fill: var(--brand-400);
      }
    }
  }
}

.user {

  .nav-link {
    background-color: #fff;
    margin-bottom: 8px;
    text-align: left;
    color: $color-text-primary;
    font-size: 14px;
    font-weight: 500;
    padding: 12px;
    border: 1px solid #ECECEC;
  }

  .user-info {
   background-color: var(--brand-500);
    color: #fff;

    .user-info-header {
      display: flex;
      align-items: center;
      padding: 18px 10px 18px 0px;

      .btn-back {
        padding: 0px 10px;
        border: 0px;
        background-color: transparent;

        svg {
          width: 24px;
          height: 24px;
        }

        svg, path {
          fill: #fff;
        }
      }
    }

    .user-info-body {
      padding: 16px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }


    h5 {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 16px;
      width: 270px;
    }

    .user-info-qrcode {
      background-color: #fff;
      border-radius: 7px;
      margin-bottom: 16px;
      width: 200px;

      img {
        width: 100%;
      }

    }

    .user-info-qrcode-text {
      width: 270px;
      font-size: 14px;
      text-align: center;
    }

    .user-info-boxes {
      min-height: 50px;
      margin-bottom: -50px;

      li .nav-link {
        color: $color-text-primary;
        display: flex;
        align-items: center;
        line-height: 1.4;

        > svg:first-child {
          margin-right: 12px;
          width: 20px;
          height: 20px;
          fill: #28A745;
          path {
            fill: #28A745;
          }
        }
        svg:last-child {
          fill:currentColor;
          //fill: $color-text-primary;
          path {
            fill:currentColor;
            //fill: $color-text-primary;
          }
        }

        small {
          display: block;
          font-size: 12px;
          font-width: 500;
          color: $color-default-light;
        }
        strong {
          font-size: 12px;
          font-width: 500;
          color: #28A745;
          display: block;
        }

        > div {
          flex-grow: 1;
          //display: flex;
          //flex
        }
      }
    }
  }

  .user-menu {
    padding: 16px;

    h3 {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 16px;
    }

    .nav-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .user-header-top {
    display: flex;
    align-items: center;
    padding: 18px 0px;

    &.top {
      border-bottom: 1px solid $color-grey-light;
    }

    h5 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0px;
    }

    .btn-back {
      padding: 0px 10px;
      border: 0px;
      background-color: transparent;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.voucher-modal {
  .modal-content {
    .voucher-full-qrcode {
      canvas {
        margin-top: -1rem;
      }

      .info {
        margin-top: -10px;
        margin-bottom: 0;
        color: $color-default-dark;
      }
    }
  }
}

.loyalty-points-modal-body {
  .rounded-icon {
    background-color: #F1FCF4;

    svg, path {
      fill: #1C793F;
    }
  }
}

.voucher-full {
  h5 {
    text-align: center;
  }

  &.voucher-modal-body {
    .rounded-icon {
      background-color: #F1FCF4;

      svg, path {
        fill: #1C793F;
      }
    }
  }

  .voucher-full-img {
    width: 100%;
    padding: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 300px;

    img {
      max-width: 100%;
      object-fit: scale-down;
      max-height: inherit;
      border-radius: 6px;
    }

    .voucher-full-img-color {
      background-color: #7C3AED;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      display: flex;
      justify-content: center; /* Centers horizontally */
      align-items: center;

      svg {
        width: 35%;
        height: 35%;
        transform: rotate(-30deg); /* Rotate 45 degrees */
        fill: #fff;
        //fill: currentColor;       /* Inherit color from parent */
        opacity: 0.5;

        path {
          fill: #fff;
          //fill: currentColor;       /* Inherit color from parent */
        }
      }
    }

    .valid-time {
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      bottom: 20px;
      left: 0px;
      right: 0px;
      margin-left: 20px;
      margin-right: 20px;
      color: #fff;
    }

    .points {
      background-color: #fff;
      border-radius: 6px;
      padding: 6px;
      position: absolute;
      top: 16px;
      right: 16px;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .voucher-full-qrcode {
    text-align: center;

    .info {
      color: $color-default-dark;
    }
  }

}

@media (min-width: 768px) {
  .user-page {
    width: 700px;
    margin: 0 auto;
  }
  .login-box {
    margin: 0 30px 30px 30px;
  }
}
