.widget {
  position: relative;
  padding-bottom: 1px;
  .cover {
    display: none;
  }
  .sticky-fixed {
    background-color: #fff;
  }
  .fixed-bottom {
  }
  .sticky-fixed-cart {
    margin-top: 16px;
    position: fixed;
    top:0px;
  }

  .sticky-fixed-cart {
    padding-top:0px;
    &.cart-sticky .cart-sticky-body .scoller {
      top:50px;
    }
  }

  .container-menu-picker ul {
    flex-direction: inherit;
    padding:0px;
    .nav-link {
      padding: 0.4rem 1rem;
      min-height:50px;
    }
    .nav-link-header {
      line-height: 38px;
    }
  }
  @media (max-width: 768px){
    .container-menu-picker ul {
      .nav-link-header {
        display: none;
      }
    }
    .modal-full {
      min-height: auto;
      .close {
        position: absolute;
      }
    }
    .modal-full .modal-footer {
      position: relative;
    }
  }
  .footer {
    .footer-store {
      display: none;
    }
  }
  .menu {
    .languages {
      display: block;
    }
  }
}
