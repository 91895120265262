
.circle-container {
  width: 100%;
  height: 224px;
  position: absolute;
  top:0px;
  margin: 24px auto; /* centers the container on the page */
  .center {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .center-svg {
    border-radius: 50%;
    border: 3px solid #fff;
    padding: 9px;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  .circle {
    width: 224px;
    height: 224px;
    border-radius: 50%;
    /* The gradient goes from top (fully colored) to bottom (transparent) */
    background: linear-gradient(
                    to bottom,
                    rgba(250, 236, 218, 0.55) 0%,
                    rgba(250, 236, 218, 0) 69.37%
    );
    /* Optional: center the circle on the page */
    margin: 50px auto;
  }

  .circle1 {
    width: 224px;
    height: 224px;
  }

  .circle2 {
    width: 164px;
    height: 164px;
  }

  .circle3 {
    width: 100px;
    height: 100px;
  }

  .circle4 {
    width: 43px;
    height: 43px;
  }
}