.page {
  .header-container {
    background-color: $color-secondary;
    padding: 48px 32px;
    border-radius: 10px;
    margin-bottom: 3rem;

    h1 {
      margin-bottom: 0;
      font-size: 36px;
      color: $color-default;
    }

    @media (max-width: 576px) {
      padding: 24px;

      h1 {
        font-size: 24px;
      }
    }
  }

  .container {
    max-width: unset;
  }

  &.rules-component {
    h4, p {
      color: $color-default-dark;
    }

    p {
      margin-bottom: 24px;
    }
  }
}