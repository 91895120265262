// Import Bootstrap and its default variables
@import "./css/variables";

@import '~bootstrap/scss/bootstrap.scss';
@import '~react-bootstrap-typeahead/css/Typeahead.css';
@import "vanilla-cookieconsent/dist/cookieconsent.css";
@import "./css/basic";

@import './css/buttons';
@import './css/modal';
@import './css/forms';
@import './css/navs';
@import './css/lists';
@import './css/badge';
@import './css/navbar';
@import './css/alerts';
@import './css/offcanvas';
@import './css/cover';
@import './css/card';
@import './css/cartSticky';
@import './css/advert';
@import './css/menu';
@import './css/page';
@import './css/cart';
@import './css/footer';
@import "./css/promotions";
@import "./css/summary";
@import "./css/voucher";
@import "./css/loading";
@import "./css/widget";
@import "./css/login";
@import "./css/product";
@import "./css/homepage";
@import "./css/multistore";
@import "./css/circle";
@import "./css/customColor";
@import "css/dark/dark";

