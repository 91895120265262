.summary-loading {
  //height:300px;
  position: relative;
}

.summary-container {

  .cart {
    margin-top: 0px;
  }

  .cart-response-restaurant {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;

    h3 {
      font-weight: 300;
      font-size: 26px;
      margin-bottom: 20px;
    }

    .cart-response-info {
      margin-bottom: 10px;

      strong {
        display: block;
        font-size: 30px;
        font-weight: 500;
        color: #2A2A2A;
      }
    }
  }

  .summary-waiting {
    text-align: center;
    //margin-bottom: 20px;
    h3 {
      font-weight: 300;
      font-size: 26px;
      margin-bottom: 20px;
    }
  }

  .card-summary {
    background-color: #F4F4F4;
  }

  .btn-default {
    cursor: auto;
    &.active, &:hover, &:focus {
      background-color: transparent;
    }

    &:focus-visible, &:active {
      box-shadow: none;
      border-color: $border-color;
    }
  }
}

.summary-total-price {
  font-size: 16px;
}

.summary-discount-price {
  color: $color-red;
}

@media (min-width: 768px) {
  .summary-loading {
    height: 250px;
  }
  .summary-container {
    max-width: 1024px;
    margin-right: auto;
    margin-left: auto;
  }
}


.summary-loading-new {
  position: relative;
 
  .summary-content {
    padding-top: 190px;
  }

  &.warning {
    h3 {
      color: #D16125;
    }

    svg, path {
      fill: #AD4B21;
    }

    .circle {
      background: linear-gradient(to bottom, rgba(250, 236, 218, 0.55) 0%, rgba(250, 236, 218, 0) 69.37%);
    }
  }

  &.danger {
    h3 {
      color: #C53D3D;
    }

    svg, path {
      fill: #A53030;
    }

    .circle {
      background: linear-gradient(to bottom, rgba(250, 230, 230, 0.55) 0%, rgba(250, 230, 230, 0) 69.37%);
    }
  }
  &.success {
    h3 {
      color: #20994D;
    }

    svg, path {
      fill: #1C793F;
    }
    .circle {
      background: linear-gradient(to bottom, rgba(223, 249, 232, 0.55) 0%, rgba(223, 249, 232, 0) 69.37%
      );
    }
  }

  .summary-response {
    text-align: center;
    margin-bottom: 32px;

    h3 {
      font-size: 18px;
      font-weight: 500;
    }
  }
  .summary-response-box {
    padding:16px;
    border: 1px solid $color-border;
    border-radius: 10px;
    dl{
      margin-bottom: 8px;
    }
    > dl:last-child {
      margin-bottom: 0px;
    }
    dt {
      font-size: 12px;
      font-weight: 400;
    }
    dd {
      font-size: 18px;
      font-weight: 500;
      color: $color-accent;
    }
  }
}