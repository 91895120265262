
.cart-sticky {
  display: none;
}
@media (min-width: 992px) {
  .cart-container {
    max-width:1024px;
    margin-right: auto;
    margin-left: auto;
  }
  .cart-sticky-container {
    border-radius: 10px;
    box-shadow: 0px -0.5px 2px 0px rgba(7, 12, 34, 0.02), 0px 1px 10px 0px rgba(7, 12, 34, 0.02);
    border: 1px solid $color-border;
    background-color: #fff;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    > .scroll {

      overflow: scroll;
    }
  }
  .cart-sticky-header{
    position: sticky;
    top: 0;
    border-bottom: 1px solid $color-border;
    h3 {
      display: flex;
      align-items: center;
      padding:24px;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0px;
    }
    svg {
      width:24px;
      height: 24px;
      margin-right: 16px;
    }
    .badge {
      margin-left: 16px;
    }
  }

  /* Scrollable body */
  .cart-sticky-body {
    flex: 1;
    overflow-y: auto;
    padding: 24px;
  }
  .cart-sticky-footer {
    padding: 24px;
    border-top: 1px solid $color-border;
    //background-color: #f8f9fa;
  }

  .cart-sticky {
    background-color: $color-secondary;
    display: block;
    position: fixed;
    top:0px;
    right:0px;
    bottom:0px;
    padding:32px;
    width:420px;
    z-index:1;
  }



  .cart-sticky-body {

    .cart-items-quantity {
      width:80px;
      .form-control {
        font-size: 12px;
      }
    }
    .cart-items-name {
      h4 {
        font-size: 15px;
      }
      h5 {
        font-size: 13px;
        margin-bottom: 3px;
      }
      ul {
        margin-bottom: 6px;
        li {
          font-size: 12px;
          ul {
            margin-left: 10px;
          }
        }
      }
    }
    .scoller {
      top: 104px;
      border-bottom: 0;
      bottom: 0px;
      padding: 0;
      position: absolute;
      //border-bottom: 1px solid #e9e9e9;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
    }
    .scoller-content {
      overflow: auto;
      display: flex;
      flex-direction: column;
    }
    .scoller-btn {
      padding:10px;
    }
  }
  .cart-sticky-summary, .cart-sticky-items {
    border-bottom: 1px solid $color-border;
  }
  .cart-sticky-summary {
    padding: $padding-lg;
  }
}