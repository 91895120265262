body > .modal-backdrop {
  z-index: 1055;
}

.modal-content {
  width: 700px;

  @media (max-width: 700px) {
    max-width: 100%;
  }
  > div {
    background-color: #fff;
  }
}
.modal-full {
  max-width: 700px;

  .modal-content {
    width: 100%;
  }

  .modal-header {
    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 8px 16px;
      z-index: 1000;
      font-size: 2rem;
      color: #282c34;
      opacity: 1;
      box-shadow: none;
      border-radius: 50%;
      line-height: 1.1;

      &:hover {
        background-color: var(--brand-500);
        color: #fff;
        opacity: 1;
      }
    }
  }
}

.small-modal {
  .modal-content {
    width: 400px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .modal-full {
    height: 100%;
    margin: 0;
    border-radius: 0;
    min-height: 100%;
    width: 100%;
    max-width: 100%;

    .modal-content {
      width: 100%;
      border: none;
    }

    .modal-header {
      .close {
        position: fixed;
        top: 10px;
        right: 10px;
        z-index: 1000;
        opacity: 1;
      }
    }

    .modal-content {
      border-radius: 0;
      height: 100%;
    }

    .modal-header-fixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }

    .modal-header-sticky {
      .modal-title {
        display: none;
      }

      .modal-header {
        border-bottom: 0;
      }
    }
  }

  .modal-footer--sticky {
    position: sticky;
    bottom: 0;
    background-color: inherit;
    z-index: 1055;
  }
}


.modal-new {
  .modal-header {
    border-bottom: none;
  }
}

.modal-info {
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    font-size: 18px;
    font-weight: 500;
  }

  .modal-info-content {
    margin-bottom: 24px;
  }

  .modal-info-actions {
    display: flex;
    width:100%;
    gap: 16px;
    > .btn {
      flex: 1 1 auto;
    }
  }
}

.pick-up-date-modal {
  &-section {
    svg {
      width: 24px;
      height: 24px;

      path {
        fill: #516077;
      }
    }

    h5 {
      font-size: 17px;
      line-height: 26px;
    }

    .elements {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 10px;
      justify-items: stretch;

      .btn {
        white-space: nowrap;

        &.selected {
          background-color: var(--brand-500);
          color: #ffffff;
        }
      }

      &.times {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      }
    }
  }
}