
.cart-summary {
  background-color: $color-secondary;
  padding: 16px;
  border-radius: 10px;

  .cart-summary-total-price {
    font-size: 16px;
  }

  .separator {
    border-top: 1px dashed #ccc;
    margin: 12px 0;
  }

  dl {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    dt {
      font-weight: 400;
    }

    dd {
      font-weight: 400;
      margin-bottom: 0px;
    }
  }

  .total {
    dt, dd {
      font-weight: 500;
      color: $color-text-primary;
    }
  }
}


//NEW
.cart {
  margin-bottom: 50px;
}
.card-cart {
  margin-bottom: 32px;
  .card-cart-header {
    margin-bottom: 16px;
    small {
      display: block;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .card-cart-body {
    .btn-check-full {
      &.active {
        background-color: transparent;
      }

      input {
        accent-color: var(--brand-500);
      }
    }
  }

  h3 {
    color: $color-default;
    font-size: 18px;
    font-weight: 500;

    .badge {
      margin-left: 16px;
    }
  }
}


.cart-items-body > .cart-item:last-child {
  border-bottom: none;
}

.cart-no-items-body {
  .circle-container {
    position: relative;

    .circle {
      background: linear-gradient(to bottom, rgba(241, 245, 249, 0.55) 0%, rgba(241, 245, 249, 0) 69.37%);
    }

    + p {
      position: absolute;
      top: 202px;
      width: 100%;
      left: 0;
      color: $color-default;
      margin-bottom: 0;
    }
  }
}

.cart-item {
  display: flex;
  border-bottom: 1px solid $color-border;
  padding-bottom: 16px;
  margin-bottom: 16px;
  color: $color-text-primary;
  h3 {
    color: $color-text-primary;
  }
  .cart-item-multilevel {
    border:1px solid $color-border;
    border-radius: 10px;
    padding:8px;
    color: $color-text-primary;
    margin-bottom: 8px;
    h5 {
      border-bottom: 1px dashed $color-border;
      font-weight: 500;
      font-size: 12px;
      padding-bottom: 4px;
      margin-bottom: 4px;
    }
  }

  .cart-item-img {
    width: 60px;
    margin-right: 12px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 10px;
    }
  }

  .cart-item-body {
    flex-grow: 1; /* Takes up the remaining space */
    h3 {
      font-size: 14px;
      font-weight: 500;
    }

    .cart-item-description {
      .order-comment {
        background-color: $color-secondary;
        color: $color-default-light;
      }
    }
  }

  .cart-item-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; /* Or whatever width you need */
  }

  .cart-item-promotion {
    margin-top: 5px;
    text-align: right;
    font-size: 75%;
    width: 70%;
    margin-left: auto;
  }

  .cart-item-prices {
    font-size: 16px;
    font-weight: 500;
    color: var(--brand-500);
  }

  .cart-item-description {
    font-size: 12px;
    margin-bottom: 8px;
  }


  ul.list-inline {
    font-size: 12px;
  }

  ul.list-inline li:after {
    content: "";
    padding-left: 2px;
  }

  ul.list-inline li.list-inline-item-multi {
    display: flex;
    flex-wrap: wrap;

    h5 {
      font-size: 12px;
      font-weight: 300;
    }
  }
}

.cart-card-info {
  padding: 16px;
  border-radius: 10px;
  border: 1px solid $color-border;

  > dl:last-child {
    margin-bottom: 0px;
  }
  dl {
    display: flex;
    margin-bottom: 8px;

    dt {
      font-weight: 400;
      margin-right: 4px;
    }

    dd {
      font-weight: 500;
      margin-bottom: 0px;
    }
  }
}

.cart-progress {
  margin-bottom: 16px;

  .progress {
    margin-bottom: 4px;
    height: 8px;
    background-color: $color-secondary;
  }

  .progress-bar {
    background-color: $color-default-light;
  }

  .cart-progress-info {
    color: $color-default-dark;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    //fill: $color-default-dark;

    strong {
      color: var(--brand-500);
    }

    svg, path {
      fill: currentColor;
      width: 16px;
      height: 16px;
    }
  }
}

.banks {
  margin-top: 8px;
  .bank-item {
    cursor: pointer;
    width: 100%;
    border: 2px solid $color-border;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    margin-bottom: 10px;

    img {
      max-width: 100%;
    }

    &:hover {
      border-color: var(--brand-500);
    }

    &.active {
      border-color: var(--brand-500);
    }
  }
}

@media (min-width: 992px) {
  .cart .card-cart-items {
    display: none;
  }
}

.cart-vouchers {
  font-weight: 500;

  svg, path {
    fill: $color-default-dark;
  }

  .empty {
    cursor: pointer;
    svg, path {
      fill: var(--brand-500);
    }

    p {
      color: var(--brand-500);
    }
  }

  svg {
    min-width: 20px;
    min-height: 20px;
  }

  span {
    cursor: pointer;
    color: var(--brand-500);
  }
}