@import "fonts/fontsRoboto";
@import "fonts/fontsIntern";

body {
  font-family: Inter, Roboto, Rubik, sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: $color-text-default;
  background-color: transparent;
  //color: $color-text-primary;
  //max-width: 1216px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  html, body {
    overscroll-behavior: none;
  }
}

.App {
  background-color: #fff;
}
.main-content {
  max-width: 1216px;
  margin: 0 auto;
}

h1, h2, h3, h4 {
  color: $color-text-primary;
}

h1 {
  font-size: 41px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 19px;
}

h5 {
  font-size: 17px;
}

a {
  color: var(--brand-500);
}

.text-primary {
  path, svg {
    fill: var(--brand-500);
  }
}

.price {
  color: var(--brand-500);
}

.header-strike {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.header-strike:before {
  content: "";
  display: block;
  border-top: solid 1px $color-border;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  z-index: 1;
}

.header-strike span {
  background: #fff;
  padding: 0 20px;
  position: relative;
  z-index: 5;
}

.badge-accent-light {
  background: var(--brand-300);
}

.icon {
  width: 24px;
  height: 24px;
}

b, strong {
  font-weight: 500;
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}

.cookies {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1035;
  padding: 15px;
  color: #fff;

  .btn {
    margin-left: 20px;
  }
}

.languages {
  text-align: center;
  width: 100%;

  &.languages-xl {
    svg {
      height: 50px;
      width: 50px;
    }
  }
}

.modal {
  .languages {
    margin-bottom: 20px;
  }
}

.horizontal-row .horizontal-text:before {
  right: 0;
}

.horizontal-row {
  margin-top: 15px;
  margin-bottom: 15px;
}

.horizontal-row .horizontal-text {
  width: 100%;
  text-align: center;
  background-color: #fff;
  position: relative;
  color: #ababab;
  font-size: 14px;
  z-index: 1;
  overflow: hidden;

  &:after, &:before {
    width: 47%;
    top: 50%;
    height: 1px;
    background-color: rgb(208, 208, 208);
    content: " ";
    position: absolute;
    overflow: hidden;
  }

  &:after {
    left: 0;
  }
}


.horizontal-divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0; /* Spacing around the divider */
  position: relative;
}

.horizontal-divider::before,
.horizontal-divider::after {
  content: '';
  flex: 1;
  border-top: 1px solid #ccc; /* Line style */
  margin: 0 0px; /* Spacing around the text */
}

.horizontal-divider span {
  white-space: nowrap; /* Prevent text from wrapping */
  padding: 0 10px; /* Add spacing around the text */
  background: #fff; /* Match background color */
  color: #333; /* Text color */
}

.hide-scrollbar {
  overflow: auto; /* Enables scrolling */
  -ms-overflow-style: none; /* Hides scrollbar in IE and Edge */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome, Safari, and Edge */
}

.dot {
  width: 8px;
  height: 8px;
  display: inline-block;
}


.icon-square {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--brand-50);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
  }

  svg, path {
    fill: var(--brand-500);
  }

  &.text-danger {
    svg, path {
      fill: #A53030;
    }

    background-color: #FCF4F4;
  }
}


@media (min-width: 992px) {
  .body-cart-sticky {
    .main {
      margin-right: 420px;
      .main-content {
        max-width: 796px;
        //margin: 0 auto;
      }
    }
  }
}