

.parent-top-nav {
  //z-index:1;
  z-index:1001;
}

.container-menu-picker {
  margin-top: 15px;
  margin-bottom: 15px;
  .nav-link-header {
    display: none;
  }
  .nav-link {
    text-align: center;
    border-radius: 6px;
    .nav-link-error {
      color: $color-red;
      display: block;
    }
    &.active {
      background-color: #F4F4F4;
      font-weight: 500;
    }
  }
  .badge {
    font-size: 100%;
    padding: $padding-default;
  }
  ul {
    flex-direction: column;
    margin-bottom: 0px;
    //font-size:75%;
  }
  ul.list-inline-hours {
    margin-left: 10px;
    margin-bottom: -1px;
    font-size: 75%;
    font-weight: 300;
    li {
      margin-right: 0px;
      padding-right: 0px;
    }
  }
  .menu-item-content {
    background-color: #F4F4F4;
    color: var(--brand-500);
    text-align: left;
    font-weight: 500;
    height:40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .menu-item-header {
      display: flex;
      align-items: center;
    }
    .menu-item-error {

      display: flex;
      flex-direction: column;
      //align-items: center;
      color: $color-red;
      font-weight: 300;
      //display: block;
    }
  }
  .input-group-append .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 50px;
    font-size: 14px;
  }
  .input-group-lg > .form-control {
    font-size: 14px;
  }
  .container-menu-picker-menus {
    .nav {
      display: none;
    }
  }
  .btn-choose-menu {
    background-color: #F4F4F4;
    color: var(--brand-500);
    display: flex;
    align-items: center;
    text-align: left;
    font-weight: 500;
    &:after {
      display: inline-block;
      margin-left: auto;
      //margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
  }
  .dropdown {
    width: 100%;
    .btn {
      text-align: left;
      width: 100%;
      background-color: var(--brand-300);
      color: #0d6efd;
      display: flex;
    }
  }
}
@media (min-width: 768px){
  .container-menu-picker {

    .container-menu-picker-menus {
      .nav {
        display: flex;
      }
      .btn-choose-menu {
        display: none;
      }
    }
    .nav-link.active {
      background-color: var(--brand-300);
    }
    .nav-link-header {
      display: block;
    }
    ul {
      flex-direction: inherit;
    }
  }
}


.nav-custom {
  flex-wrap: nowrap;
  margin-bottom: 16px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  .nav-link {
    white-space: nowrap !important;
    padding: 12px 0;
    color: $color-text-default;
    font-weight: 500;
    font-size: 14px;
    margin-right: 24px;
      cursor: pointer;
  }
  .nav-item.active .nav-link{
    color: $color-text-primary;
    font-weight: 500;
    border-bottom: 2px solid $color-text-primary;
  }

}

.nav-scrolling-buttons-wrapper {
  background-color: transparent;
  position: unset;
  padding: 0;

  > .navigate-left, > .navigate-right {
    display: none;
  }

  .navigate-right, .navigate-left {
    z-index: 999;

    .btn {
      border-radius: 50%;

      &:active {
        background: $color-secondary;
        border-color: $color-border;
        color: $color-default-dark;
      }
    }
  }

  .nav-tabs-top {
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: nowrap;
    position: relative;
    z-index: 980;
    background-color: transparent;

    &::-webkit-scrollbar {
      height: 0;
    }

    .nav-item {
      cursor: pointer;
    }

    .nav-link {
      white-space: nowrap !important;
      padding: 1rem 1rem;
      color: $color-text-default;

      &:hover {
        color: $color-text-primary;
      }
    }

    .nav-item.active {
      .nav-link {
        font-weight: 500;
        border-bottom: 3px solid $color-text-primary;
        color: $color-text-primary;
      }
    }
  }
}
@media (min-width: 992px) {
  .nav-scrolling-buttons-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    > .navigate-left, > .navigate-right {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0px;
      bottom: 0px;
    }

    > .navigate-left {
      left: 0px;
    }

    > .navigate-right {
      right: 0px;
    }
  }
}