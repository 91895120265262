.btn {
  border-radius: 6px;
  //line-height: 1.286;
  //line-height: 20px;
  //line-height: 24px;

  &.is-invalid {
    border-color: #dc3545 !important;
  }

  h5 small {
    font-weight: 400;
  }

  svg {
    //width: 20px;
    //height: 20px;
    //width: 1rem;
    height: 1rem;
    width: auto;
  }

  svg, path {
    fill: currentColor;
  }
}

.btn-flex {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 8px;
    margin-left: 8px;
    //width: 20px;
    //height: 20px;
  }
}

.btn-lg {
  padding: 11px;
  font-size: 14px;

  svg, path {
    height: 1.25rem;
    width: auto;
  }
}

.btn-sticky {
  padding: 15px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  position: fixed;
}


.btn-secondary {
  background-color: #E8F1FF;
  border-color: #E8F1FF;
  color: #000;
}

.btn-outline-secondary {
  border-color: $color-border;
  color: $color-default-dark;
}

.btn-transparent {
  color: var(--brand-300);
  background: transparent;
}

.main, html {
  .btn-primary {
    color: #fff;
    border-color: var(--brand-500);
    background: var(--brand-500);

    &:hover, .active {
      color: #fff;
      border-color: var(--brand-700);
      background: var(--brand-700);
    }

    &[disabled] {
      background: var(--brand-400);
      border-color: var(--brand-300);
    }
  }

  .btn-outline-primary {
    background-color: transparent;
    border-color: var(--brand-500);
    color: var(--brand-500);

    &:hover, &:active, &:focus {
      background: var(--brand-500);
      color: white;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .star {
    background-color: var(--brand-50);

    svg > path {
      fill:  var(--brand-500);
    }
  }

}

.btn-primary-light, .btn-accent-light {
  color: var(--brand-500);
  border-color: var(--brand-300);
  background: var(--brand-300);
}

.btn-default {
  font-weight: 500;
  border-color: $color-border;
  color: $color-default-dark;
  background: #fff;
  //background: transparent;

  &:hover, &.active {
    background: $color-secondary;
    border-color: $color-border;
    color: $color-default-dark;
    //color: $color-primary-reverse;
  }

  &.active {
    &:focus, &:active, &:hover, &.focus {
      border-color: $color-border;
      background: $color-secondary;
      color: $color-default-dark;
      //color: $color-primary-reverse;
    }
  }

  &.focus {
    background: transparent;
  }

  &:disabled, &.disabled {
    opacity: 0.4;
    border-color: $color-border;
    color: $color-default-dark;
  }
}


.btn-default1 {
  font-weight: 500;
  border-color: $color-border;
  color: $color-text-default;
  background: transparent;

  &:hover, &.active {
    background: $color-secondary;
    border-color: $color-border;
    color: $color-default-dark;
    //color: $color-primary-reverse;
  }

  &.active {
    &:focus, &:active, &:hover, &.focus {
      border-color: $color-border;
      background: $color-secondary;
      color: $color-default-dark;
      //color: $color-primary-reverse;
    }
  }

  &.focus {
    background: transparent;
  }
}

.btn-outline-danger {
  border-color: $color-border;

  path, svg {
    fill: $color-red;
  }

  &:hover {
    path, svg {
      fill: $color-white;
    }
  }
}

.btn-sm {
  svg, path {
    max-height: 18px;
  }
}

.btn {
  &.disabled-view {
    opacity: 0.65;
  }
}

.btn-icon {
  display: flex;
  align-items: center;
  padding: 0px;
  justify-content: center;

  svg, path {
    height: 20px;
  }

  width: 36px;
  height: 36px;

  &.btn-lg {
    width: 48px;
    height: 48px;
  }

  &.btn-sm {
    width: 20px;
    height: 20px;

    svg, path {
      height: 18px;
    }
  }
}

.btn-spinner {
  position: relative;

  .left {
    float: left;
  }

  .right {
    float: right;
  }

  :first-child {
    //margin-right: auto;
  }

  .spinner-border {
    float: left;
    width: 20px;
    height: 20px;
    //width: 1.5rem;
    //height: 1.5rem;
    display: none;
  }

  :last-child {

  }

  &.loading {
    &.btn-icon svg {
      display: none;
    }
    .spinner-border {
      display: block;
    }
  }
}

.btn-submit {
  &:disabled, &[disabled] {
    .btn-submit-quantity {
      display: none;
    }
  }

  width: 100%;
  padding: $padding-lg;

  .btn-submit-quantity {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    border: 1px solid #fff;
    height: 24px;
    padding: 0px 7px;
  }
}

.btn-submit-container {
  left: 10px;
  bottom: 10px;
  right: 10px;
}

.btn-group-submit {
  width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  position: relative;
  display: inline-flex;
  vertical-align: middle;

  .input-group-quantity {
    width: auto;
    flex-wrap: nowrap;
    display: flex;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-right: 7px;

    .input-group-text {
      width: calc(1ch + 42px);
    }

    button {
      width: 48px;
      height: 48px;
    }
  }
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: .5rem;
}

.btn-pick-date {
  h5 {
    font-size: 17px;

    small {
      display: block;
    }
  }
}

.btn-promotion {
  background-color: #F5F5F5;
  color: $color-text-primary;
  font-size: 14px;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  position: relative;
  padding: 11px;

  &.highlight {
    border: 1px solid var(--brand-500);
    border-radius: 4px;
    background-color: #fff;
  }

  .content {
    line-height: 16px;
  }

  .star {
    text-align: center;
    //flex: 0 0 32px;
    border-radius: 40px;
    width: 32px;
    height: 32px;
    padding-top: 4px;
    padding-left: 1px;
    margin-right: 20px;
  }

  > div:last-child {
    &.icon {
      margin-left: auto;
    }
  }

  &.loading {
    .icon svg {
      display: none;
    }
  }

  small {
    display: block;
  }

  .arrow {
    fill: #2A2A2A;
    margin-left: auto;
  }

  svg {
    fill: #fff;
  }
}

.input-group-quantity {
  align-items: stretch;
  flex-wrap: nowrap;
  color: $color-text-primary;

  .input-group-prepend, .input-group-append {
    button {
      font-weight: 700;
      font-size: 24px;
    }
  }

  .input-group-prepend button {
    //border-top-right-radius: 0px;
    //border-bottom-right-radius: 0px;
  }

  .input-group-append button {
    //border-top-left-radius: 0px;
    //border-bottom-left-radius: 0px;
  }

  .input-group-text {
    color: $color-text-primary;
    font-weight: 400;
    font-size: 14px;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 14px;
    background: transparent;
  }

  &.input-group-sm {
    .input-group-prepend, .input-group-append {
      button {
        font-size: 20px;
      }
    }

    .input-group-text {
      font-size: 14px;
      font-weight: 500;
      padding: 2px 10px;
    }

    button {
      width: 30px;
      height: 30px;
      padding: 0px;
    }
  }
}

.list-nowraps {
  overflow: auto;
  display: flex;

  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    white-space: nowrap;
  }
}

.btn-groups-columns {
  .btn {
    margin-bottom: 8px;
  }

  .btn-group {
    display: flex;
    flex-direction: column;
  }
}

.btn-check-full {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.active {
    border-color: var(--brand-500);
  }

  svg {
    margin-right: 12px;
  }

  span {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 32px;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;

    small {
      color: $color-default;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;

      &.accent {
        color: var(--brand-500);
      }
    }
  }

  > :last-child {
    margin-left: 12px;
    //padding-left: 12px;
    //margin-left: auto;
  }
}

.btn-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 32px;
    line-height: 16px;
    flex: 1;
    small {
      color: $color-default;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
    }
  }

  > svg:last-child {
    margin-left: auto;
  }

  > :first-child {
    margin-right: 12px;
  }
}

.rounded-icon {
  &.large {
    width: 48px;
    height: 48px;
  }

  &.medium {
    width: 40px;
    height: 40px;
    padding: 10px;

    svg {
      max-width: 20px;
      max-height: 20px;
    }
  }

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-secondary;

  svg, path {
    fill: #334155;
  }
}

.dropdown-toggle {
  &.show, &.btn:active {
    border-color: $border-color;
  }
}