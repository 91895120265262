.multistore-places {
  background-color: $color-secondary;
  margin-bottom: 32px;
  padding: 32px 0px 26px 0px;

  > .container-fluid {
    max-width: 577px;
  }
}

.card-place {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px -0.5px 2px 0px rgba(7, 12, 34, 0.02), 0px 1px 10px 0px rgba(7, 12, 34, 0.02);
  padding: 12px;
  display: flex;
  align-items: stretch;

  .card-place-wrapper {
    flex: 1;
  }

  .card-place-navigate {
    align-items: center;
    display: flex;

    svg {
      width: 20px;
      height: 20px;
    }

    padding-left: 12px;
  }

  .card-header {
    align-items: center;
    display: flex;
    border-bottom: 1px solid $color-border;
    padding-bottom: 12px;
    margin-bottom: 12px;

    .icon-square {
      margin-right: 12px;
    }

    .card-header-text {
      flex: 1;
    }

    span {
      font-size: 12px;
    }

    h3 {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0px;
    }
  }

  .icon-list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 8px;

    svg {
      flex: 0 0 16px; /* Fixed width of 16px, does not grow or shrink */
      width: 16px;
      height: 16px;
      margin-right: 8px;

      path {
        fill: $color-default;
      }
    }

    > li {
      display: flex;
      align-items: center;
    }
  }

  margin-bottom: 8px;

  .phone {
    svg {

    }

    span {
      display: none;;
    }
  }
}

.main {
  .multistore-location {
    margin-bottom: 16px;

    .btn-outline-primary {
      background-color: #ffffff;
      border-color: var(--brand-200);

      &:hover {
        background-color: var(--brand-200);
        color: #fff;
      }
    }
  }
}

.multistore-places-content {
  padding-bottom: 8px;
}

.multistore-places-buttons {
  display: flex;

  .btn:not(:first-child) {
    margin-left: 16px;
  }
}

.map-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.map-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.multistore-map {
  height: calc(100vh - 64px); /* Fallback for older browsers */
  height: calc(100dvh - 64px); /* Modern browsers */
  //margin-top: 56px; // Ensure content starts below a fixed navbar
  overflow: hidden; // Prevent overflow if needed
  //height:100%;
  > div {
    height: 100%;
  }

  .map-item {
    margin: 10px;
    border-radius: 8px;
    background-color: #fff;
    padding: 10px;
    //border: 1px solid $color-primary;
  }

  .marker-current-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--brand-500);
    width: 32px;
    height: 32px;
    border-radius: 999px;
    box-shadow: 0px 2px 8px 0px var(#070C22, rgba(7, 12, 34, 0.20));
    border: 2px solid var(--brand-50);;

    svg, path {
      fill: #fff;
      width: 24px;
      height: 24px;
    }
  }

  .marker-place-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 999px;
    background-color: #fff;
    padding: 7px;
    box-shadow: 0px 2px 8px 0px var(#070C22, rgba(7, 12, 34, 0.15));

    &.active {
      width: 44px;
      height: 44px;
      border: 2px solid var(--brand-600);

      svg {
        height: 21px;
      }

      svg, path {
        fill: var(--brand-400);
      }

      img {
        height: 27px;
        max-height: 27px;
      }
    }

    svg {
      height: 22px;
    }

    img {
      height: 25px;
      max-height: 25px;
    }

    svg, path {
      fill: var(--brand-700);
    }
  }
}

.multistore-map-controls {
  text-align: right;
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 1000;
}

.multistore-map-bottom {
  margin-top: -16px;
  z-index: 1001;

  .card-place {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

  }

  //position: absolute;
  //bottom: 16px;
  //left: 16px;
  //right: 16px;
  //z-index: 1000;
}

.multistore-map-search {
  position: absolute;
  top: 16px;
  left: 54px;
  right: 16px;
  z-index: 1000;
  width: calc(100% - 46px - 16px);

  .btn {
    border-radius: 999px;
  }
}

.multistore-controls {
  display: flex;
  flex-direction: column;
  align-items: end;

  .btn {
    margin-bottom: 16px;
    height: 56px;
    width: 56px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.multistore-list-controls {
  position: fixed;
  bottom: 16px;
  left: 16px;
  right: 16px;
  z-index: 1000;
}

.multistore-list {
  height: calc(100vh - 64px); /* Fallback for older browsers */
  height: calc(100dvh - 64px); /* Modern browsers */
  background-color: $color-secondary;
}

.multistore-list-navs {
  background-color: #fff;
  display: flex;
}

.multistore-list-content {
  background-color: $color-secondary;
  margin-bottom: 32px;
  padding: 16px 0px 16px 0px;
}


@media (min-width: 992px) {

  .multistore-map-bottom {
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
    z-index: 1000;
  }
  .multistore-map-search {
    display: none;
  }
  .multistore-list-controls {
    display: none;
  }
  .multistore-controls-action-list {
    display: none;
  }
  .multistore-map-controls {
    //display: none;
  }
  .multistore-full {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 64px); /* Fallback for older browsers */
    height: calc(100dvh - 64px); /* Modern browsers */
    .multistore-full-map {
      flex: 1;
    }

    .multistore-full-list {
      background-color: $color-secondary;
      width: 450px;
      overflow-y: auto;
    }
  }
  .card-place {
    .phone {
      svg {
        display: none;
      }

      span {
        display: inherit;
      }
    }
  }
}

