.homepage-menus {
  background-color: $color-secondary;
  margin-bottom: 32px;
  padding:32px 0px 26px 0px;
}

.nav-complex {
  .nav-link {
    display: flex;
    align-items: center;
  }
  .nav-link {
    margin-bottom: 8px;
  }
}
.nav-link-homepage {
  padding: 12px;
  background-color: #fff;
  //border: 1px solid $color-border;
  box-shadow: 0px -0.5px 2px 0px rgba(7, 12, 34, 0.02), 0px 1px 10px 0px rgba(7, 12, 34, 0.02);
  color: $color-text-default;
  &:hover {
    background-color: $color-border;
    //color: #fff;
  }
  .icon-square {
    margin-right: 12px;
  }
  > svg:last-child {
    margin-left: auto;
  }
  svg, path {
    fill: $color-text-default;
  }
  h4 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
  }
  p {
    font-size: 12px;
    margin-bottom: 0;
  }
}

.homepage-advert-user {
  margin-bottom: 32px;
  > .container-fluid {
    max-width: 577px;
  }
}