$color-red: #A53030;
$color-green: #28A745;
$color-blue: #086EC5;
//$color-blue-light: #E8F1FF;
$color-blue-light: #EDF9FF;

$color-black: #000000;
$color-black-light: #202020;
$color-grey: #434343;
$color-grey-dark: #3b3b3b;
$color-grey-light: #9b9b9b;
$color-grey-super-light: #e2e2e2;
$color-white: #ffffff;
$color-white-light: #fafaf9;
$color-white-dark: #f4f4f3;

$color-text-primary: #2A2A2A;
$color-text-secondary: #535353;
$color-accent: $color-blue;
$color-accent-light: $color-blue-light;
$color-accent-dark: #1F286B;
//$color-default: #36343B;
//$color-border: #dee2e6;
//$color-border: #DCE6F6;

$color-primary-reverse: #fff;
$color-text-primary-light: #b8b8b8;

$padding-sm: 5px;
$padding-default: 10px;
$padding-lg: 15px;
$padding-xl: 20px;

$success: $color-green;


//NEW
$color-default: #526077;
$color-default-light: #94A3B8;
$color-default-dark: #101729;
$color-text-primary: $color-default-dark;

$color-default: #526077;
$color-secondary: #F1F5F9;
$color-border: #E2E8F0;

$color-text-default: $color-default;

$body-color: $color-text-default !default;

// Override Bootstrap variable for button height
//$input-btn-padding-y: 0.5rem !default; // Adjust padding-y to match 36px height
//$input-btn-padding-y: 0.3125rem !default; // Adjust padding-y to match 36px height
$input-btn-padding-y: 0.4067rem !default; // Adjust padding-y to match 36px height
$input-btn-padding-x: 1rem !default;   // Optional: Adjust padding-x if needed
$font-size-base: 0.875rem !default;    // Adjust base font size if required

// Calculate button height based on padding, font size, and border
$btn-height-base: calc(2 * $input-btn-padding-y + $font-size-base + 2px) !default;

$danger: #A53030; // Replace with your desired color
$warning: #AD4B21; // Replace with your desired color
$primary: $color-accent; // Replace with your desired color
