.promotions {
  max-width: 100%;
  width: 100%;
  overflow-x: hidden;
  background-color: $color-secondary;
  padding: 24px;
  margin-bottom: 2.5rem;
  position: relative;
  border-radius: 10px;

  @media (max-width: 991px) {
    padding: 24px 16px;
    max-width: calc(100% + (var(--bs-gutter-x) * 0.5 * 2));
    width: calc(100% + (var(--bs-gutter-x) * 0.5 * 2));
    margin-left: calc(var(--bs-gutter-x) * -0.5);
    margin-right: calc(var(--bs-gutter-x) * 0.5);
    border-radius: 0;
  }

  .nav-tabs-top {
    gap: 1rem;
    width: 100%;
  }

  .btn-promotion {
    background-color: #ffffff;
    color: $color-text-primary;
    font-size: 14px;
    display: flex;
    align-items: center;
    min-width: 312px;
    max-width: 312px;
    text-align: left;
    position: relative;
    padding: 1rem;
    border: 1px solid $border-color;

    @media(max-width: 500px) {
      &.single {
        min-width: 100%;
        max-width: 100%;
      }
    }

    &:hover, &:focus, &:active {
      border-color: #20994D;
    }

    &:active {
      color: $color-text-primary;
      background-color: #ffffff;
    }

    .icon-container {
      border-radius: 10px;
      min-width: 80px;
      min-height: 80px;
      background-color: #F1FCF4;
      margin-right: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin: auto;
        path {
          fill: #20994D;
        }
      }
    }
  }
}