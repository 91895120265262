.card-advert {
  background: linear-gradient(84deg, #070C22 21.08%, #334155 97.74%);
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border-color: var(--brand-400);

  .card-body {
    padding: 24px 16px;
    display: flex;
  }

  strong {
    color: #86EFAC;
  }

  svg {
    fill: #86EFAC;
    margin-right: 20px;
    width:24px;
    height:24px;
    path {
      fill: #86EFAC;
    }
  }
}