:root {
  --brand-50: #EDF9FF;
  --brand-100: #D6F0FF;
  --brand-200: #B5E7FF;
  --brand-300: #83D9FF;
  --brand-400: #48C2FF;
  --brand-500: #1EA2FF;
  --brand-600: #0085E9;
  --brand-700: #086EC5;
  --brand-800: #0D5E9B;
  --brand-900: #0E385D;
  --brand-950: #0B2846;
}